import 'core-js/fn/array/from';
import 'core-js/es6/symbol';
import 'core-js/es6/promise';
import 'core-js/web/dom-collections';
import { addPolyfills, initMenu, initForms } from './modules';

const init = () => {
  addPolyfills();
  initMenu();
  initForms();
};

document.addEventListener('DOMContentLoaded', init);
