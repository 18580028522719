import { $$ } from 'utils';
// import CustomForm from './custom-form';

export default async () => {
  const forms = $$('[data-form="wrapper"]');
  if (!forms.length) {
    return;
  }
  const { default: CustomForm } = await import('./custom-form.js').catch((e) =>
    console.error(`Clouldn't get CustomForm class: ${e}`)
  );

  CustomForm && [...forms].forEach((form) => new CustomForm(form));
};
