import { $ } from 'utils';

class Menu {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.menuItems = wrapper.querySelectorAll('[data-menu="items"]');
    this.toggleMobileButton = wrapper.querySelectorAll(
      '[data-menu="toggle-mobile-button"]'
    );
    this.cssClasses = {
      opened: 'menu_opened',
    };
    this._opened = false;
    this.onToggleMobileButtonClick = this.onToggleMobileButtonClick.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);

    this.toggleMobileButton.on('click', this.onToggleMobileButtonClick);
  }

  get opened() {
    return this._opened;
  }

  set opened(bool) {
    this.wrapper.classList.toggle(this.cssClasses.opened);

    document.body[bool ? 'on' : 'off']('click', this.onDocumentClick);

    this._opened = bool;
  }

  onDocumentClick({ target }) {
    if (!this.wrapper.contains(target)) {
      this.opened = false;
    }
  }

  onToggleMobileButtonClick() {
    this.opened = !this.opened;
  }
}

export default () => {
  const menu = $('[data-menu="wrapper"]');
  menu && new Menu(menu);
};
